.App {
  text-align: center;
  background-color: beige;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  --blue-primary: #2d70c2;
  --blue-dark: #1d1c4f;
  --blue-light: #90c2fe;

  --yellow-primary: #fcd100;

  --red-primary: #fcd100;

  --green-primary: #008790;
  --green-light: #4cc8d0;

  --gray-dark: #4a4a4a;
  --gray-primary: #606977;
  --gray-medium: #b0bac7;
  --gray-light: #d7dae0;
  --gray-pale: #f3f6fb;

  --white: #ffffff;
  --black: #000000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.react-tel-input .form-control {
  border: 0 !important;
  border-bottom: 1px solid #4a4a4a !important;
  width: 100% !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.coupon-img {
  max-height: 300px;
  max-width: 300px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

.wrapper {
  background: #406596;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  background: linear-gradient(45deg, #4d9da5, #3c5792);
  /* box-shadow: 10px 10px 20px #41858c, -10px -10px 20px #59b5be; */
  border-radius: 20px;
}

.container > .left,
.container > .right {
  padding: 1rem;
}

.container > .left .coupan {
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  max-width: 600px;
}

.container > .left .package {
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  display: flex;
}

.package .title {
  font-size: 3rem;
  letter-spacing: 1px;
  /* color: #ec8413; */
  color: #f3af2c;
  margin: 1rem 0;
}

.container > .left .sharing-buttons {
  display: flex;
  /* padding: 1rem 0; */
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* height: 40px */
}
.buttoncopy {
  margin-top: 30px;
  background-color: #3c5792; /* Green */
  border: none;
  color: white;
  transition: transform 0.2s linear;
  padding: 15px 32px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  font-size: 16px;
}
.container > .left .sharing-buttons button {
  width: fit-content;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  margin: 1rem;
  cursor: pointer;
  background: linear-gradient(45deg, #c74c66, #e7551d);
  transition: transform 0.2s linear;
  letter-spacing: 0.8px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;
}

.container > .left .sharing-buttons a {
  width: fit-content;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  margin: 1rem;
  cursor: pointer;
  background: linear-gradient(45deg, #c74c66, #e7551d);
  transition: transform 0.2s linear;
  letter-spacing: 0.8px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;
}

.container > .left .sharing-buttons button:hover,
.buttoncopy:hover {
  transform: scale(1.1);
}

.container > .left .sharing-buttons a:hover {
  transform: scale(1.1);
}

.container > .left .sharing-buttons a img {
  width: 23px;
  object-fit: contain;
  margin: 0 0 0 10px;
}

.container > .left .sharing-buttons button img {
  width: 23px;
  object-fit: contain;
  margin: 0 0 0 10px;
}

.coupan .heading {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.2rem;
  color: #f3af2c;
}

.coupan .title {
  font-size: 2rem;
  letter-spacing: 1px;
  /* color: #ec8413; */
  color: #f3af2c;
  margin: 1rem 0;
  white-space: pre-wrap;
}

.coupan .description {
  color: #eee;
  font-size: 1.1rem;
  letter-spacing: 0.9px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
 /* .coupan .description .img-con */
.coupan .description .img-con {
    width: 100%;
    min-height: 300px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
}

.coupan .description p {
  padding: 0 1rem 0 0;
  max-height: 140px;
  overflow-y: auto;
}

/* width */
.coupan .description p::-webkit-scrollbar {
  width: 8px;
  overflow: hidden;
}

/* Track */
.coupan .description p::-webkit-scrollbar-track {
  background: #3f6696;
}

/* Handle */
.coupan .description p::-webkit-scrollbar-thumb {
  background: #f3af2c;
}

/* Handle on hover */
.coupan .description p::-webkit-scrollbar-thumb:hover {
  background: #58939a;
}

.coupan .inner-coupan > .left {
  background-color: white;
  border-right: 4px dashed rgb(255, 115, 20);
  max-width: 40%;
  font-size: 3rem;
}

.coupan .inner-coupan > .right {
  flex: 1;
}

.container > .right .img-container {
  max-width: 500px;
}

@media screen and (min-width: 960px) {
  .container {
    flex-direction: row;
  }

  .coupan .heading {
    font-size: 1.5rem;
  }

  .coupan .title {
    font-size: 2.5rem;
  }

  .coupan .description {
    flex-direction: row;
  }
  

  .coupan .description p {
    padding: 0 1rem 0;
  }

  .container > .left .sharing-buttons {
    flex-direction: row;
  }
}

.coupan .custom-grid{
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width:700px) {
  .coupan .custom-grid{

   grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width:400px) {
  .coupan .custom-grid{
   
   grid-template-columns: 1fr;
  }
}
